<template>
  <div class="system-setting">
    <div class="set-form">
      <h3 class="c-title">账号设置</h3>
      <el-form
        ref="form"
        :model="user"
        :rules="userRules"
        label-width="80px"
        style="width: 50%; margin: 20px 90px"
      >
        <el-form-item label="手机号" prop="number">
          <el-input v-model="user.number"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="user.password"
            show-password
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          ><el-button type="primary" style="float: right" @click="updateUser"
            >修 改</el-button
          ></el-form-item
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import { setUser, userData } from "../../api/require";
export default {
  data() {
    return {
      user: {
        number: "",
        password: "",
      },
      userRules: {
        number: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          { required: true, min: 6, message: "密码最少6位", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    async updateUser() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const md5 = require("js-md5"); //md5加密
          const res = await setUser(this.user.number, md5(this.user.password));
          if (res.data.Code == -1) {
            this.$refs["form"].fields[0].validateState = "error";
            this.$refs["form"].fields[0].validateMessage = res.data.Msg;
          } else {
            this.$message({
              message: res.data.Msg,
              type: "success",
            });
          }
        } else {
          return false;
        }
      });
    },
    async getUserData() {
      const { data: res } = await userData();
      this.user.number = res.User.phoneNum;
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.system-setting {
  width: 100%;
  height: 100%;
  background: #eaeaeb;
  position: relative;
  .c-title {
    padding-left: 10px;
    border-left: 5px solid skyblue;
    font-size: 17px;
    font-weight: bold;
    color: rgb(73, 71, 71);
    margin: 20px 90px;
  }
  .set-form {
    width: 50%;
    height: 70%;
    margin: 0 auto;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>